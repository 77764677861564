var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vx-card",
    { class: _vm.getRowClass(_vm.field) },
    [
      !_vm.hideQuestionScore
        ? _c("div", { staticClass: "grid grid-rows-1" }, [
            _c("div", { staticClass: "grid grid-cols-2" }, [
              _c("div", { staticClass: "float-let" }, [
                _c("span", { staticStyle: { "font-size": "12px" } }, [
                  _vm._v(_vm._s(_vm.scoreSet(_vm.field))),
                ]),
              ]),
              _c("div", { staticClass: "float-right" }, [
                _c(
                  "label",
                  {
                    class: _vm.field.correction
                      ? "float-right text-right text-success"
                      : "float-right text-right text-danger",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("points") +
                          ": " +
                          (_vm.field.score
                            ? _vm.formmatedScore(_vm.field.score)
                            : 0)
                      )
                    ),
                  ]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        [
          _vm.field.type === "section"
            ? _c("section-answer", {
                attrs: { item: { data: { title: _vm.field.title } } },
              })
            : _vm._e(),
          _vm.field.type === "simple_text"
            ? _c("simple-text-show", {
                attrs: {
                  item: {
                    data: Object.assign({}, _vm.getQuestionData(_vm.field), {
                      title: _vm.field.title,
                    }),
                    answer: _vm.field.answer,
                    correction: _vm.field.correction,
                    feedback: _vm.field.feedback,
                    scoreset: _vm.field.scoreset,
                  },
                },
              })
            : _vm._e(),
          _vm.field.type === "multiple_choice"
            ? _c("multiple-choice-show", {
                attrs: {
                  item: {
                    data: Object.assign({}, _vm.getQuestionData(_vm.field), {
                      title: _vm.field.title,
                      options: _vm.getMultipleChoiceOptions(
                        _vm.field.question_id
                      ),
                    }),
                    chosen_option_id: _vm.field.chosen_option_id,
                    correction: _vm.field.correction,
                    feedback: _vm.field.feedback,
                    scoreset: _vm.field.scoreset,
                  },
                },
              })
            : _vm._e(),
          _vm.field.type === "check_box"
            ? _c("check-box-show", {
                attrs: {
                  item: {
                    data: Object.assign({}, _vm.getQuestionData(_vm.field), {
                      title: _vm.field.title,
                      options: _vm.getCheckBoxOptions(_vm.field.question_id),
                    }),
                    chosen_option_id: _vm.field.chosen_option_id,
                    correction: _vm.field.correction,
                    feedback: _vm.field.feedback,
                    scoreset: _vm.field.scoreset,
                  },
                },
              })
            : _vm._e(),
          _vm.field.type === "gap"
            ? _c("gap-show", {
                attrs: {
                  item: {
                    answer: _vm.field.answer,
                    data: Object.assign({}, _vm.getQuestionData(_vm.field), {
                      title: _vm.field.title,
                      options: _vm.getGapOptions(_vm.field.question_id),
                    }),
                    chosen_option_id: _vm.field.chosen_option_id,
                    correction: _vm.field.correction,
                    feedback: _vm.field.feedback,
                    scoreset: _vm.field.scoreset,
                  },
                  showCorrectAnswer: _vm.viewAnswers,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      !_vm.print
        ? _c("h5", { staticClass: "mt-4 mb-4 border-bottom" })
        : _vm._e(),
      _vm._t("after"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }