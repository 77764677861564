var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ml-4 mr-4 mt-4 w-full" }, [
    _c("div", { staticClass: "flex mb-5" }, [
      _c(
        "div",
        { staticClass: "bg-grid-color-secondary mr-2 w-full" },
        [
          _c("div", {
            directives: [
              {
                name: "html-safe",
                rawName: "v-html-safe",
                value: _vm.title,
                expression: "title",
              },
            ],
            staticClass: "editor-content ck-content unselectable",
          }),
          !_vm.isEmpty(_vm.description)
            ? _c("vs-divider", { staticClass: "m-1" })
            : _vm._e(),
          !_vm.isEmpty(_vm.description)
            ? _c("div", {
                directives: [
                  {
                    name: "html-safe",
                    rawName: "v-html-safe",
                    value: _vm.description,
                    expression: "description",
                  },
                ],
                staticClass: "mt-2",
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }