<template>
  <vx-card :class="getRowClass(field)" >
    <div class="grid grid-rows-1" v-if="!hideQuestionScore">
      <div class="grid grid-cols-2">
        <div class="float-let">
          <span style="font-size: 12px;">{{scoreSet(field)}}</span>
        </div>
        <div class="float-right">
          <label :class="field.correction ? 'float-right text-right text-success' : 'float-right text-right text-danger'">{{`${$t('points')}: ${field.score ? formmatedScore(field.score) : 0}`}}</label>
        </div>
      </div>
    </div>
    <div>
      <section-answer
      :item="{data: {title: field.title}}"
      v-if="field.type === 'section'"
      ></section-answer>
      <simple-text-show
      :item="{data: {...getQuestionData(field), title: field.title}, answer: field.answer, correction: field.correction, feedback: field.feedback, scoreset: field.scoreset}"
      v-if="field.type === 'simple_text'"
      ></simple-text-show>
      <multiple-choice-show
      :item="{data: {...getQuestionData(field), title: field.title, options: getMultipleChoiceOptions(field.question_id)}, chosen_option_id: field.chosen_option_id, correction : field.correction, feedback: field.feedback, scoreset: field.scoreset}"
      v-if="field.type === 'multiple_choice'"
      ></multiple-choice-show>
      <check-box-show
      :item="{data: {...getQuestionData(field), title: field.title, options: getCheckBoxOptions(field.question_id)}, chosen_option_id: field.chosen_option_id, correction : field.correction, feedback: field.feedback, scoreset: field.scoreset}"
      v-if="field.type === 'check_box'"
      ></check-box-show>
      <gap-show
      :item="{answer: field.answer, data: {...getQuestionData(field), title: field.title, options: getGapOptions(field.question_id)}, chosen_option_id: field.chosen_option_id, correction : field.correction, feedback: field.feedback, scoreset: field.scoreset}"
      v-if="field.type === 'gap'"
      :showCorrectAnswer="viewAnswers"
      ></gap-show>
    </div>

    <h5 v-if="!print" class="mt-4 mb-4 border-bottom"></h5>
    <slot name="after">
    </slot>
  </vx-card>
</template>

<script>
import { getQuestion } from '@/util/QuestionnaireUtil'
import SectionAnswer from '../SectionAnswer.vue'
import SimpleTextShow from './SimpleTextShow.vue'
import MultipleChoiceShow from './MultipleChoiceShow.vue'
import GapShow from './GapShow.vue'
import CheckBoxShow from './CheckBoxShow.vue'

export default {

  props: {
    field: {
      type: Object,
      default: null
    },
    questionnaireData: {
      type: Object,
      default: null
    },
    hideQuestionScore: {
      type: Boolean,
      default: false
    },
    viewAnswers: {
      type: Boolean,
      default: false
    },
  },

  components: {
    SectionAnswer,
    SimpleTextShow,
    MultipleChoiceShow,
    GapShow,
    CheckBoxShow,
  },

  computed: {
    questionnaireFields() {
      return this._.get(this.questionnaireData, 'fields', [])
    }
  },

  methods: {
    getRowClass(data) {
      const base = 'mr mb-2'
      const questionId = this._.get(data, 'question_id')

      if (questionId) {
        const question = getQuestion(questionId, this.questionnaireData.fields, {})
        const isInvalidated = this._.get(question, 'data.invalidated.justification', false)
        if (isInvalidated !== false) {
          return `${base} invalidated-item`
        }
      }

      return base
    },
    scoreSet(field) {
      if (!this.isEmpty(field.scoreset) && !this.hideQuestionScore) {
        const scoreset = parseFloat(field.scoreset).toFixed(2).replace('.', ',')
        return this.$t('valor-scoreset', [scoreset])
      }
      return ''
    },
    formmatedScore(score) {
      return !this.isEmpty(score) ? parseFloat(score).toFixed(2).replace('.', ',') : ''
    },
    getQuestionData(field) {
      const question = this.questionnaireFields.find((question) => question.id === field.question_id)

      if (question) {
        return question.data
      }
    },
    getMultipleChoiceOptions(questionUUID) {
      const found = this.questionnaireData['fields'].find(field => {
        if (field.type === 'multiple_choice' && field.id === questionUUID) {
          return true
        } else {
          return false
        }
      })
      return found ? found.data.options : null
    },
    getCheckBoxOptions(questionUUID) {
      const found = this.questionnaireData['fields'].find(field => {
        if (field.type === 'check_box' && field.id === questionUUID) {
          return true
        } else {
          return false
        }
      })
      return found ? found.data.options : null
    },
    getGapOptions(questionUUID) {
      const found = this.questionnaireData['fields'].find(field => {
        if (field.type === 'gap' && field.id === questionUUID) {
          return true
        } else {
          return false
        }
      })
      return found ? found.data.options : null
    },
    
  }
}
</script>

<style>

</style>