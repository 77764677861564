export function getQuestionSection(questionId: string, fields: Array<any>) {
  let currentSection = 0
  let foundAt = 0

  fields.forEach((field, pos) => {
    if (field.id === questionId) {
      foundAt = currentSection
    }

    if (
      (pos === 0 && field.type !== 'section') ||
      (pos !== 0 && field.type === 'section')
    ) {
      currentSection++
    }
  })

  return foundAt
}

export function getQuestionType(questionId: string, fields: Array<any>) {
  const found = fields.findIndex((field) => {
    return field.id === questionId
  })

  if (found !== -1) {
    return ('type' in fields[found] && fields[found].type) || null
  }

  return null
}

export function getQuestion(questionId: string, fields: Array<any>) {
  const found = fields.findIndex((field) => {
    return field.id === questionId
  })

  if (found !== -1) {
    return fields[found]
  }

  return null
}
