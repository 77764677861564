<template>
  <div :class="printStyle()">
    <vs-row v-if="print" class="mt-4 fixRowWidth">
      <vs-col>
        <vx-card :title="$t('respostas')" class="mb-4">
          <div class="vx-row w-full">
            <div class="vx-col sm:w-4/12 w-4/12">
            <b>{{$t('participant')}}</b>
            </div>
            <div class="vx-col sm:w-5/12 w-5/12">
            <b>{{$t('Questionnaire')}}</b>
            </div>
            <div class="vx-col sm:w-3/12 w-3/12">
            <b>{{$t('Release_Date')}}</b>
            </div>
          </div>

          <div class="vx-row w-full">
            <div class="vx-col sm:w-4/12 w-4/12">{{userName}}</div>
            <div class="vx-col sm:w-5/12 w-5/12">{{details.questionnaireName}}</div>
            <div class="vx-col sm:w-3/12 w-3/12">{{details.scoreReleasedAt}}</div>
          </div>
          
          <div class="mt-2 mb-2 grid grid-cols-3 align-baseline">
            <label class="font-semibold block row-start-1">{{$t('points')}}</label>
            <span class="row-start-2">{{formatFloat(answerData.total_score)}} / {{answerData.max_total_score}} {{ scorePercentage(answerData) }}</span>
            <label class="font-semibold block row-start-1">Média da Turma</label>
            <span class="row-start-2">{{formatFloat(details.scoreAverage)}}</span>
          </div>

        </vx-card>
      </vs-col>
    </vs-row>
    <vs-row class="mt-2">
      <vs-col class="mt-2">
        <div>
          <vs-row v-for="(field, i) in answers" :key="i">
            <vs-col
                vs-type="flex"
                vs-justify="left"
                vs-align="left"
                vs-w="12"
                class="list-item"
            >
              <question-show-card :field="field" v-bind="$props">
                <template v-slot:after>
                  <div v-if="!print">
                    <div>
                      <feather-icon
                        @click="showCommentSectionModal(field)"
                        :badge="unreadComments(field.comments, field.question_id)"
                        icon="MessageSquareIcon"
                        class="mb-2 float-right cursor-pointer"
                        svgClasses="text-success"
                        v-tooltip.top="{
                          content: $t('Open_comments'),
                          delay: { show: 1000, hide: 100 }
                        }"
                      >
                      </feather-icon>
                    </div>
                  </div>
                </template>
              </question-show-card>
            </vs-col>
          </vs-row>
          <vs-row v-if="print">
            <div class="vx-col block w-full grid">
              <div class="flex items-stretch mx-auto place-self-center">
                <label class="text-primary text-sm mr-2">{{$t('Powered_by')}}</label>
                <a href="https://educatena.com.br" target="_blank">
                  <img src="@/assets/images/logo/logo.png" style="max-width: 100px;"/>
                </a>
              </div>
            </div>
          </vs-row>
        </div>
      </vs-col>
    </vs-row>

    <vs-popup :title="$t('comments')" :active.sync="showCommentSection">
      <AnswerCommentSection
        ref="commentsection"
      ></AnswerCommentSection>
    </vs-popup>

  </div>
</template>

<script>

import QuestionnaireAnswerService from '@/services/api/QuestionnaireAnswerService'
import AnswerCommentSection from '../answers/AnswerCommentSection'
import QuestionShowCard from './questions/show/QuestionShowCard.vue'

export default {
  components: {
    AnswerCommentSection,
    QuestionShowCard
  },
  props : {
    answers: {
      type: Object,
      default: null
    },
    questionnaireData: {
      type: Object,
      default: null
    },
    // Do not change this prop's name, its used in the router
    answer_id: { 
      type: Number,
      default: null
    },
    // Do not change this prop's name, its used in the router
    questionnaire_short_url: { 
      type: String,
      default: null
    },
    hideQuestionScore: {
      type: Boolean,
      default: false
    },
    viewAnswers: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    questionnaireAnswerService: null,
    answerData: null,
    print: false,
    details: {
      questionnaireName: '',
      scoreReleasedAt: '',
      scoreAverage: 0.0
    },
    showCommentSection: false,
    comments: {
      readCache: []
    }
  }),

  computed: {
    userName() {
      return this.$store.state.AppActiveUser.name
    },
    hasAverageScore() {
      return this.details.scoreAverage !== undefined || this.details.scoreAverage !== null
    },
  },
  watch: {
    details() {
      document.title = `Educatena - ${  this.details.questionnaireName}`
      if (this.print) {
        setTimeout(() => {
          window.print()
        }, 500)
      }
    }
  },
  created() {
    if (this.answer_id && !this.isEmpty(this.questionnaire_short_url)) {
      this.questionnaireAnswerService = QuestionnaireAnswerService.build(this.$vs)
      this.$vs.loading()
      this.questionnaireAnswerService.allResults(this.questionnaire_short_url).then(
        data => {
          const answer = data.answersList.filter(_answer => {
            return +_answer.answer_id === +this.answer_id
          })
          this.$set(this.$data, 'answerData', answer[0])
          this.questionnaireData = data.questionnaireData
          this.$set(this.$data, 'details', {
            questionnaireName: data.questionnaireName,
            scoreReleasedAt: data.score_released_at,
            scoreAverage: (
              data.scoreResume &&
              data.scoreResume.average
            ) || 0
          })
          this.print = true
          this.answers = this.answerData.answers
          this.hideQuestionScore = data.hide_question_score
          this.$vs.loading.close()
        },
        error => {
          this.$vs.loading.close()
        }
      )

    }
  },
  methods : {
    scorePercentage(answer) {
      if (answer.max_total_score <= 0) return ''
      const percentage = Math.ceil((answer.total_score / answer.max_total_score) * 100)
      return `( ${percentage}% )`
    },
    printStyle() {
      if (this.print) {
        return 'mx-auto w-11/12 lg:w-10/12 xl:w-9/12'
      }
    },
    showCommentSectionModal(answerData) {
      this.$refs.commentsection.getData(answerData.answer_id, answerData.question_id)
      if (this.comments.readCache.indexOf(answerData.question_id) === -1) {
        this.comments.readCache.push(answerData.question_id)
      }
      this.showCommentSection = true
    },
    unreadComments(count, question_id) {
      if (this.comments.readCache.indexOf(question_id) === -1) {
        return count
      } else {
        return 0
      }
    },
  }
}
</script>

<style>
  .fixRowWidth {
    width: auto !important;
  }
</style>
