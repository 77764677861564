
<template>
  <div class="ml-4 mr-4 mt-4 w-full">
    <div class="flex mb-5">
      <div class="bg-grid-color-secondary mr-2 w-full">
        <div class="editor-content ck-content unselectable" v-html-safe="title"></div>
        <vs-divider class="m-1" v-if="!isEmpty(description)"/>
        <div v-html-safe="description" class="mt-2" v-if="!isEmpty(description)"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    answer_id: {
      type: Number,
      default: null,
    },
    user_id: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    service: null,
  }),
  computed: {
    title() {
      const title = this.html2text(this.item.data.title)
      return title !== null && title !== ''
        ? this.item.data.title
        : `<h1>${this.$t('sem-titulo')}</h1>`
    },
    description() {
      //console.log('this.item.data...', this.item.data)
      const description = this.html2text(this.item.data.description)
      return description !== null && description !== ''
        ? this.item.data.description
        : ''
    },
  },
  methods: {},
  beforeMount() {
    //   this.service = ContentQuestionnaireService.build(this.$vs)
  },
}
</script>
